import { Fragment, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Menu } from "../../components/menu"
import { TableRow } from "./tableRow";
import Select from "react-select";
import { ChevronDown, ChevronUp } from "tabler-icons-react"
import { UseCasesContext } from "../../../context/useCases"; import { useTypedSelector } from "../../../hooks/useTypedSelector";
import Multiselect from 'multiselect-react-dropdown';

export const Tabel = () => {
    let { id } = useParams();

    const [operatorOptions, setOperatorOptions] = useState<string[]>([])
    const [operatorNamesToLoginMap, setOperatorNameToLoginMap] = useState<Map<string, string>>(new Map())
    const [operatorLoginToNameMap, setOperatorLoginToNameMap] = useState<Map<string, string>>(new Map())
    const [responsibleOptions, setResponsibleOptions] = useState<string[]>([])

    const [daysInSelectedMonth, setDaysInMonth] = useState<number>(0)

    const getLastMonthDate = () => {
        let date = new Date()
        date.setMonth(new Date().getMonth() - 1)
        return date
    }
    const [form, setForm] = useState<{
        month: number,
        year: number,
        rgs: string[],
        operatorLogins: string[],
        roleChecked: boolean,
    }>({
        month: getLastMonthDate().getMonth() + 1,
        year: getLastMonthDate().getFullYear(),
        rgs: [],
        operatorLogins: [],
        roleChecked: false,
    })

    const [impExDropDownShow, setImportExportDropDownShow] = useState<boolean>(false)
    const [importModalShow, setImportModalShow] = useState<boolean>(false)

    let useCases = useContext(UseCasesContext)

    const daysInMonth = (year: number, month: number) => {
        return new Date(year, month, 0).getDate();
    }

    const readRgs = async () => {
        let response = await useCases?.reportUseCase.ReadLeaderNamesForSearch()

        if (response instanceof Error) {

        } else {

            setResponsibleOptions(response!)
        }
    }


    useEffect(() => {
        setDaysInMonth(daysInMonth(form.year, form.month))
        if (form.roleChecked) {
            useCases?.tabelUseCase.ReadMonthTabel(form.month, form.year, form.rgs, form.operatorLogins)
        }

    }, [form])

    useEffect(() => {
        checkIfUserIsRg()
        useCases?.reportUseCase.ReadOperatorsForSearch(new Date(form.year, form.month, 1, 0, 0, 0, 0), new Date(form.year, form.month, 31, 0, 0, 0, 0)).then((response) => {
            if (response instanceof Error) {

            } else {
                let newOperatorOptions: string[] = []
                let operatorNameToLoginMap: Map<string, string> = new Map<string, string>();
                let operatorLoginToNameMap: Map<string, string> = new Map<string, string>();

                response.forEach((pi) => {
                    newOperatorOptions.push(pi.name!)
                    operatorNameToLoginMap.set(pi.name!, pi.login!)
                    operatorLoginToNameMap.set(pi.login!, pi.name!)
                })

                setOperatorOptions([...newOperatorOptions])
                setOperatorNameToLoginMap(operatorNameToLoginMap)
                setOperatorLoginToNameMap(operatorLoginToNameMap)
            }
        })
    }, [])

    const checkIfUserIsRg = async () => {
        if (useCases?.authUseCase.IsHeadOfGroup()) {
            let userId = useCases.authUseCase.UserId()
            let userName = await useCases.userUseCase.ReadUserNameById(userId)
            if (userName instanceof Error) {

            } else {
                setForm({
                    ...form,
                    rgs: [userName],
                    roleChecked: true,
                })

            }

            return

        }

        setForm({
            ...form,
            roleChecked: true,
        })


        return false
    }

    const namesToLogins = (input: string[]) => {
        let logins: string[] = []
        input.forEach((name) => {
            logins.push(operatorNamesToLoginMap.get(name)!)
        })
        return logins
    }

    const loginsToNames = (input: string[]) => {
        let names: string[] = []
        input.forEach((login) => {
            names.push(operatorLoginToNameMap.get(login)!)
        })

        return names
    }



    const tabel = useTypedSelector(({ tabel }) => {
        return tabel
    })

    const downloadReportXlsx = async (e: any) => {
        e.preventDefault()
        if (form) {
            let file = await useCases?.tabelUseCase.ReadMonthTabelXlsx(form.month, form.year, form.rgs, form.operatorLogins)

            if (file instanceof Error) {

            } else {

                var link = document.createElement('a');
                link.href = process.env.REACT_APP_BACKEND_URL + "/api/files/tabels.xlsx";
                link.target = "_blank"
                //link.download = "result.xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        setImportExportDropDownShow(false);
    }

    useEffect(() => {
        readRgs()
    }, [])




    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4">Табель рабочего времени операторов</h4>
                        </div>
                        <div className="col-sm-12 col-md-8">
                            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                <button type="button" onClick={(e) => { downloadReportXlsx(e) }} className={"dt-button add-new btn btn-primary mb-3 mb-md-0"} data-bs-toggle="dropdown" aria-expanded="false">
                                    Выгрузить в Excel
                                </button>
                            </div>
                        </div>
                        <div className="card mb-4" style={{ zIndex: 800 }}>
                            <div className="card-body">
                                <div className="row g-3">
                                    <div className="col-md-4">
                                        <label className="form-label" htmlFor="multicol-cityname">Оператор</label>
                                        {operatorOptions.length > 0 ? <Multiselect
                                            isObject={false}
                                            options={operatorOptions}
                                            selectedValues={loginsToNames(form.operatorLogins)}
                                            placeholder=""
                                            onSelect={(selectedList: any) => {
                                                setForm({
                                                    ...form,
                                                    operatorLogins: namesToLogins(selectedList),
                                                })
                                            }}

                                            onRemove={(selectedList: any) => {
                                                setForm({
                                                    ...form,
                                                    operatorLogins: namesToLogins(selectedList),
                                                })
                                            }}
                                            displayValue="operatorLogins"
                                        /> : <></>}
                                    </div>
                                    {!useCases?.authUseCase.IsHeadOfGroup() ? <div className="col-md-4">
                                        <label className="form-label" htmlFor="multicol-cityname">Ответственный</label>
                                        {responsibleOptions.length > 0 && !useCases?.authUseCase.IsHeadOfGroup() ? <Multiselect
                                            isObject={false}
                                            options={responsibleOptions}
                                            selectedValues={form.rgs}
                                            placeholder=""
                                            onSelect={(selectedList: string[]) => {
                                                setForm({
                                                    ...form,
                                                    rgs: selectedList,
                                                })
                                            }}

                                            onRemove={(selectedList: string[]) => {
                                                setForm({
                                                    ...form,
                                                    rgs: selectedList,
                                                })
                                            }}
                                            displayValue="rgs"
                                        /> : <></>}

                                    </div> : <></>}
                                    <div className="col-md-4">
                                        <label className="form-label" htmlFor="multicol-email">Дата</label>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="input-group">
                                                    <select value={form.month} onChange={(e) => {
                                                        setForm({
                                                            ...form,
                                                            month: parseInt(e.target.value)
                                                        })
                                                    }} name={"from_month"} className="form-select" >
                                                        <option value="1">Январь</option>
                                                        <option value="2">Февраль</option>
                                                        <option value="3">Март</option>
                                                        <option value="4">Апрель</option>
                                                        <option value="5">Май</option>
                                                        <option value="6">Июнь</option>
                                                        <option value="7">Июль</option>
                                                        <option value="8">Август</option>
                                                        <option value="9">Сентябрь</option>
                                                        <option value="10">Октябрь</option>
                                                        <option value="11">Ноябрь</option>
                                                        <option value="12">Декабрь</option>
                                                    </select>
                                                    <select value={form.year} onChange={(e) => {
                                                        setForm({
                                                            ...form,
                                                            year: parseInt(e.target.value)
                                                        })
                                                    }} name={"from_year"} className="form-select" >
                                                        <option disabled selected value="0">{"Выберите год"}</option>
                                                        <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                                                        <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                                                        <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="table_scroll_main">
                            {/* <div className="table_scroll_str table_scroll_str_l">
                            <img src="img/str_l.svg"/>
                        </div>
                        <div className="table_scroll_str table_scroll_str_r">
                            <img src="img/str_r.svg"/>
                        </div>*/}
                            <div className="table_scroll table table-time">
                                <div className="css_table">
                                    <div className="css_thead">
                                        <div className="css_tr">
                                            <th className="css_th css_fix_lt" rowSpan={2} style={{ backgroundColor: "#ffff" }}>Сотрудник</th>
                                            <th className="css_th css_zi" rowSpan={2} style={{ backgroundColor: "#ffff" }}>Оплач-е<br /> часы</th>
                                            <th className="css_th css_zi" rowSpan={2} style={{ backgroundColor: "#ffff" }}>Факт.отраб.<br /> часы (ФОЧ)</th>
                                            <th className="css_th css_zi" rowSpan={2} style={{ backgroundColor: "#ffff" }}>% Пост<br /> отр (%П)</th>
                                            {Array.from(Array(daysInSelectedMonth).keys()).map((index) => {
                                                return <>
                                                    <th className="css_th css_th_top" colSpan={2} style={{ backgroundColor: "#ffff" }}>{index + 1}</th>
                                                </>
                                            })}
                                        </div>
                                        <div className="css_tr">
                                           
                                            {Array.from(Array(daysInSelectedMonth).keys()).map((index) => {
                                                return <>
                                                    <th className="css_th css_th_top2" style={{ backgroundColor: "#ffff" }}> ФОЧ</th>
                                                    <th className="css_th css_th_top2" style={{ backgroundColor: "#ffff" }}> %П</th>
                                                </>
                                            })}

                                        </div>
                                    </div>
                                    <div className="css_tbody">
                                        {
                                            tabel?.leader_groups && tabel?.leader_groups!.map((lg, index) => {
                                                return <TableRow leaderGroup={lg} daysInMonth={daysInSelectedMonth} last={(tabel?.leader_groups!.length - 1 == index) && index != 0 ? true : false} />
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

const TableHeaderWithSorting = ({ title, param, setSortingValue, active }: { title: string, param: string, setSortingValue: (input: string) => void, active: boolean }) => {
    return <th style={{ cursor: "pointer", display: "flex", alignItems: "center", width: "100%" }} onClick={() => { setSortingValue(param) }}>{title.indexOf("<br />") !== -1 ? <Fragment>{title.split("<br />")[0]}<br /> {title.split("<br />")[1]}</Fragment> : title} <div style={{ display: "flex", flexDirection: "column", marginLeft: 5 }}><ChevronUp size={15} color={active ? "black" : "grey"} /> <ChevronDown size={15} color={!active ? "black" : "grey"} /></div></th>
}

const TableHeaderWithSortingNew = ({ title, param, setSortingValue, active }: { title: string, param: string, setSortingValue: (input: string) => void, active: boolean }) => {
    return <div className="css_th">
        <div className="css_th_str">
            {title.indexOf("<br />") !== -1 ? <Fragment>{title.split("<br />")[0]}<br />{title.split("<br />")[1]}</Fragment> : title}
            <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px" }} onClick={() => { setSortingValue(param) }}>
                <ChevronUp size={15} color={active ? "black" : "grey"} /> <ChevronDown size={15} color={!active ? "black" : "grey"} />
            </div>
        </div>
    </div>


}