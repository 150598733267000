import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { Login } from "../../pages/auth/login/login";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useContext, useEffect } from "react";
import { UseCasesContext } from "../../../context/useCases";
import { UserList } from "../../pages/users/list";
import { UserAdd } from "../../pages/users/add";

import { ChangePass } from "../../pages/users/change-pass";
import { CalcParamsInfo } from "../../pages/calcparams/info";
import { CalcParamsUpdate } from "../../pages/calcparams/calcparams_update/calcparams_update";
import { Report } from "../../pages/report";
import { HeadsZp } from "../../pages/heads_zp";
import { LearnZp } from "../../pages/learn_zp";
import { RooZp } from "../../pages/roo_zp";
import { TeamleadZp } from "../../pages/teamlead-zp";
import { Settings } from "../../pages/settings";
import { Tabel } from "../../pages/tabel";
export const RouterComponent = () => {
  let useCases = useContext(UseCasesContext)

  const login = useTypedSelector(({ login }) => {
    return login
  })

  useEffect(() => {
    useCases?.authUseCase.CheckAuthorization()
    setInterval(() => {
      useCases?.authUseCase.CheckAuthorization()
    }, 10000)
  }, [])


  return (<Router basename="/">
    <Routes>
      {login?.authorized ?
        <>
          <Route path="/tabel" element={<Tabel />} />

          <Route path="/heads-zp" element={<HeadsZp />} />
          <Route path="/learn-zp" element={<LearnZp />} />
          <Route path="/roo-zp" element={<RooZp />} />
          <Route path="/teamlead-zp" element={<TeamleadZp />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/calc-params/edit" element={<CalcParamsUpdate />} />
          <Route path="/calc-params" element={<CalcParamsInfo />} />
          <Route path="/user/password/:id" element={<ChangePass />} />
          <Route path="/user/edit/:id" element={<UserAdd />} />
          <Route path="/user/add" element={<UserAdd />} />
          <Route path="/user" element={<UserList />} />

          <Route path="*" element={ useCases?.authUseCase.IsHr() ? <Tabel /> : <Report />} />
        </>
        : <>
          <Route path="*" element={<Login />} />
        </>}
    </Routes>
  </Router>
  )
}