import { BrandReddit, Cash, ChartDots3, CurrencyRubel, Logout, Settings, TableAlias, } from 'tabler-icons-react';

export const Items = [
    {
        title: "Зарплата операторов",
        icon: <CurrencyRubel size={24} className="menu-icon"  />,
        href: "/zp",
        permission: ['admin', 'head_of_contact_center', 'head_of_group', 'roo', 'team_lead']
    },

    {
        title: "Зарплата наставников и РГ",
        isAdmin: true,
        isAdminAndHeadOfContact: true,
        icon: <Cash size={24} className="menu-icon"  />,
        href: "/heads-zp",
        permission: ['admin', 'head_of_contact_center']
    },

    {
        title: "Зарплата Отдела обучения и РГ",
        isAdminAndHeadOfContact: true,
        icon: <Cash size={24} className="menu-icon"  />,
        href: "/learn-zp",
        permission: ['admin', 'head_of_contact_center']
    },

    {
        title: "Зарплата Отдела обучения и РОО",
        isHeadOfGroupe: true,
        isAdminAndROO: true,
        icon: <Cash size={24} className="menu-icon"  />,
        href: "/roo-zp",
        permission: ['admin', 'head_of_contact_center', 'roo']
    },

    {
        title: "Зарплата РГ и Тимлида",
        isAdminAndTeamLead: true,
        icon: <Cash size={24} className="menu-icon"  />,
        href: "/teamlead-zp",
        permission: ['admin', 'head_of_contact_center', 'team_lead']
    },

    {
        title: "Табель рабочего времени операторов",
        icon: <TableAlias size={24} className="menu-icon"  />,
        href: "/tabel",
        permission: ['admin', 'head_of_contact_center', 'head_of_group', 'roo', 'hr', 'team_lead']
    },

    {
        title: "Условия расчета",
        isAdmin: true,
        icon: <ChartDots3 size={24} className="menu-icon"  />,
        href: "/calc-params",
        permission: ['admin']
    },
    
    {
        title: "Учетные записи",
        isAdmin: true,
        icon: <BrandReddit size={24} className="menu-icon"  />,
        href: "/user",
        permission: ['admin']
    },

    {
        title: "Настройки",
        isAdmin: true,
        icon: <Settings size={24} className="menu-icon"  />,
        href: "/settings",
        permission: ['admin']
    },

    {
        title: "Выход",
        icon: <Logout size={24} className="menu-icon" />,
        href: "/exit",
        permission: ['admin', 'head_of_contact_center', 'head_of_group', 'roo', 'hr', 'team_lead']
    },
]