import axios from "axios";
import { Payment } from "../../../domain/payment/payment";
import { Configuration, PaymentApi, PaymentPaymentResponse, ReportOperatorPaymentsOfMonth } from "../generated";
import { PaymentInterface } from "../../../useCase/payment/adapters/interface";

export class PaymentsRepository implements PaymentInterface {
    private service: PaymentApi;

    constructor() {
        this.service = new PaymentApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async CreateOrUpdatePayment(operatorId: UniqueId, start: Date, end: Date, avansStudy: boolean, avans: number, workedHours: number, penalty: number, mainBonus: number, scoreboardBonus: number, groupBonus: number, hoursSurcharge: number, projectsSurcharge: number, bank: string, processedPayments: string[], confirmPayment: boolean, changeSalary: number): Promise<ReportOperatorPaymentsOfMonth | Error> {
        try {
            let response = await this.service.apiPaymentPost({operator_id: operatorId, start: this.formatDate(start), end: this.formatDate(end), study_avans: avansStudy, avans: avans, worked_hours: workedHours, penalty: penalty, main_bonus: mainBonus, scoreboard_bonus: scoreboardBonus, group_bonus: groupBonus, hours_surcharge: hoursSurcharge, projects_surcharge: projectsSurcharge,  bank: bank, processed_payments: processedPayments, confirm_salary: confirmPayment, change_salary: changeSalary }, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data!
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    formatDate = (date: Date) => {

        let month = '' + (date.getMonth() + 1)
        let day = '' + date.getDate()
        let year = date.getFullYear()

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    async ReadPaymentForOperatorInrange(operatorId: UniqueId, from: Date, to: Date): Promise<Payment | Error> {
        try {
            let response = await this.service.apiPaymentOperatorIdStartEndGet(operatorId, this.formatDate(from), this.formatDate(to), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return this.toDomainPayment(response.data!)
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.error
            }
            
            return Error(error)
        }
        
        return Error("error authorization")
    }

    async SetPaymentProcessedByOperatorIdAndDateRange(operatorId: UniqueId, from: Date, to: Date, payementType: string): Promise<ReportOperatorPaymentsOfMonth | Error> {
        try {
            let response = await this.service.apiPaymentOperatorIdStartEndPaymentTypePut(operatorId, this.formatDate(from), this.formatDate(to), payementType, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data!
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.error
            }
            
            return Error(error)
        }
        
        return Error("error authorization")
    }

    async ImportBonusesFromXlsx(file?: File): Promise<string | Error> {
        try {
            let response = await this.service.apiPaymentBonusesPost(file, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "ok"
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.error
            }
            
            return Error(error)
        }
        
        return Error("error authorization") 
    }

    toDomainPayment = (input: PaymentPaymentResponse) => {
        return new Payment(input.id!, input.operator_id!, new Date(input.start!), new Date(input.end!), input.study_avans!, input.avans!, input.worked_hours!, input.penalty!, input.main_bonus!, input.scoreboard_bonus!, input.group_bonus!, input.hours_surcharge!, input.bank!, input.processed_payments!, input.confirm_salary!, input.change_salary!, input.projects_surcharge!, input.salary!, new Date(input.created_at!), new Date(input.modified_at!))
    }
    
}