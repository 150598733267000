import { FC, ReactElement } from "react";
interface Props {
    children: ReactElement;
    isAllowed: string[];
    userRole: string;
};

export const PermissionNavigate: FC<Props> = ({children, isAllowed, userRole}): ReactElement | null => {
    if (!isAllowed.includes(userRole)) {
        return null;
    }

    return (children)
}