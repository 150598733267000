import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { FiledType, Form } from "../../../components/form"
import { Menu } from "../../../components/menu"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { UserList } from "../../../../repository/api/generated"

export const UserAdd = () => {
    const navigate = useNavigate();

    let { id } = useParams();
    const userRoles = [
        {
            title: "Администратор",
            value: "admin",
        },
        {
            title: "РКЦ (руководитель колл-центра) ",
            value: "head_of_contact_center",
        },
        {
            title: "РГ (руководитель группы)",
            value: "head_of_group",
        },
        {
            title: "HR",
            value: "hr",
        },
        {
            title: "РОО",
            value: "roo",
        },
        {
            title: "Тимлид",
            value: "team_lead",
        },
    ]

    let useCases = useContext(UseCasesContext)
    const [form, setForm] = useState<{
        name: string,
        login: string,
        pass: string,
        role: string,
        mentorIs1Id: number,
        mentorIs2Id: number,
        headOperatorId: number,
        responseTrans: number,
        isActive: boolean,
    }>({
        name: "",
        login: "",
        pass: "",
        role: "",
        mentorIs1Id: 0,
        mentorIs2Id: 0,
        headOperatorId: 0,
        responseTrans: 0,
        isActive: false
    })

    const [mentorsIs1, setMentorsIs1List] = useState<{title: string, value: number}[]>([])
    const [mentorsIs2, setMentorsIs2List] = useState<{title: string, value: number}[]>([])
    const [headOperators, setHeadOperators] = useState<{title: string, value: number}[]>([])
    const [responsibleTransOptions, setResponsibleTransOptions] = useState<{ title: string, value: number }[]>([])

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (form.name !== "" && form.login !== "" && form.pass !== "" && form.role.length !== 0) {
            if (id) {
                useCases?.userUseCase.UpdateUser(id!, form.name!, form.login!, form.role!, form.mentorIs1Id, form.mentorIs2Id, form.headOperatorId, form.isActive, form.responseTrans, () => { navigate(-1) })
            } else {
                useCases?.userUseCase.CreateUser(form.name!, form.login!, form.pass!, form.role!, form.mentorIs1Id, form.mentorIs2Id, form.headOperatorId, form.isActive, form.responseTrans, () => { navigate(-1) })
            }
        }
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    const readUserById = async (id: UniqueId) => {
        let response = await useCases?.userUseCase.ReadUserById(id)

        if (response instanceof Error) {

        } else {
            setForm({
                name: response?.Name()!,
                login: response?.Login()!,
                pass: response?.Pass()!,
                role: response?.Role()!,
                mentorIs1Id: response?.IsMentorId()!,
                mentorIs2Id: response?.Is2MentorId()!,
                headOperatorId: response?.HeadOperatorId()!,
                responseTrans: response?.ResponseTrans()!,
                isActive: response?.IsActive()!,
            })
        }
    }

    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy;
    }

    useEffect(() => {
        if (id) {
            readUserById(id)
        }
    }, [id])

    const users = useTypedSelector(({ user }) => {
        return user
    })

    const updateFields = [
        {
            name: "name",
            title: "Имя",
            placeholder: "Укажите имя",
            value: form.name,
            type: FiledType.Text,
        },
        {
            name: "login",
            title: "Логин",
            placeholder: "Укажите логин",
            value: form.login,
            type: FiledType.Text,
        },
        {
            name: "role",
            title: "Роль пользователя",
            placeholder: "Выберите роль пользователя",
            value: form.role,
            type: FiledType.Select,
            options: userRoles,
        },
        {
            name: "mentorIs1Id",
            title: "Привяжите поле Наставник ИС-1 из АМО (нужно только для РГ)",
            placeholder: "Выберите из списка",
            value: form.mentorIs1Id,
            type: FiledType.SelectInt,
            options: mentorsIs1,
        },
        {
            name: "mentorIs2Id",
            title: "Привяжите поле Наставник ИС-2 из АМО (нужно только для РГ)",
            placeholder: "Выберите из списка",
            value: form.mentorIs2Id,
            type: FiledType.SelectInt,
            options: mentorsIs2,
        },
        {
            name: "headOperatorId",
            title: "Привяжите поле Руководитель из АМО (нужно только для РГ)",
            placeholder: "Выберите из списка",
            value: form.headOperatorId,
            type: FiledType.SelectInt,
            options: headOperators,
        },
        {
            name: "responseTrans",
            title: "Привяжите поле ответственного по сделке (только для РОО и тимлида)",
            placeholder: "Выберите из списка",
            value: form.responseTrans,
            type: FiledType.SelectInt,
            options: responsibleTransOptions,
        },
        {
            name: "isActive",
            title: "Активность",
            placeholder: "Активность",
            value: form.isActive,
            type: FiledType.Checkbox,
        },
    ]

    useEffect(() => {
        useCases?.userUseCase.ReadUsers()
       
    }, [])

    useEffect(()=>{
        useCases?.userUseCase.ReadDictionaries().then((response)=>{
            if (response instanceof Error) {

            } else {
                let mentorsIs1: {title: string, value: number}[] = []
                let mentorsIs2: {title: string, value: number}[] = []
                response!.is1_mentors?.map((mnt) => {
                    mentorsIs1.push({title: mnt.name!, value: mnt.id!})
                })
                response!.is2_mentors?.map((mnt) => {
                    mentorsIs2.push({title: mnt.name!, value: mnt.id!})
                })
                let headOperators: {title: string, value: number}[] = []
                response!.head_operator?.map((ho) => {
                    headOperators.push({title: ho.name!, value: ho.id!})
                })

                setMentorsIs1List([...mentorsIs1])
                setMentorsIs2List([...mentorsIs2])

                setHeadOperators([...headOperators])
            }
        })
    },[])

    useEffect(() => {
        useCases?.reportUseCase.ReadTransOperatorsForSearch().then((response) => {
            if (response instanceof Error) {

            } else {
                const responsibleTransArraySet = Array.from(response).map((el, i) => {
                    return { value: el.id, title: el.name }
                });

                // @ts-ignore
                setResponsibleTransOptions( responsibleTransArraySet || [] )
            }
        })
    }, []);

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Пользователь /</span> {id ? "Редактировать пользователя" : "Добавить пользователя"}</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">{id ? "Редактирование пользователя" : "Добавление пользователя"}</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: false,
                                        error: "",
                                    }}

                                    submit={submit}

                                    fields={id ? updateFields : [
                                        {
                                            name: "name",
                                            title: "Имя",
                                            placeholder: "Укажите имя",
                                            value: form.name,
                                            type: FiledType.Text,
                                        },
                                        {
                                            name: "login",
                                            title: "Логин",
                                            placeholder: "Укажите логин",
                                            value: form.login,
                                            type: FiledType.Text,
                                        },
                                        {
                                            name: "pass",
                                            title: "Пароль",
                                            placeholder: "Укажите пароль",
                                            value: form.pass,
                                            type: FiledType.Text,
                                        },
                                        {
                                            name: "role",
                                            title: "Роль пользователя",
                                            placeholder: "Выберите роль пользователя",
                                            value: form.role,
                                            type: FiledType.Select,
                                            options: userRoles,
                                        },
                                        {
                                            name: "mentorIs1Id",
                                            title: "Привяжите поле Наставник ИС-1 из АМО (нужно только для РГ)",
                                            placeholder: "Выберите из списка",
                                            value: form.mentorIs1Id,
                                            type: FiledType.SelectInt,
                                            options: mentorsIs1,
                                        },
                                        {
                                            name: "mentorIs2Id",
                                            title: "Привяжите поле Наставник ИС-2 из АМО (нужно только для РГ)",
                                            placeholder: "Выберите из списка",
                                            value: form.mentorIs2Id,
                                            type: FiledType.SelectInt,
                                            options: mentorsIs2,
                                        },
                                        {
                                            name: "headOperatorId",
                                            title: "Привяжите поле Руководитель из АМО (нужно только для РГ)",
                                            placeholder: "Выберите из списка",
                                            value: form.headOperatorId,
                                            type: FiledType.SelectInt,
                                            options: headOperators,
                                        },
                                        {
                                            name: "responseTrans",
                                            title: "Привяжите поле ответственного по сделке (только для РОО и тимлида)",
                                            placeholder: "Выберите из списка",
                                            value: form.responseTrans,
                                            type: FiledType.SelectInt,
                                            options: responsibleTransOptions,
                                        },
                                        {
                                            name: "isActive",
                                            title: "Активность",
                                            placeholder: "Активность",
                                            value: form.isActive,
                                            type: FiledType.Checkbox,
                                        },
                                    ]}
                                    btnSmall={true}
                                    submitBtnTitle={"Сохранить"}
                                    updateForm={updateForm}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
