import { log } from "console";
import { UsersRepository } from "../../repository/api/user/user";
import { actionCreators } from "../../state";

export class UserUseCases {
    private api: UsersRepository;
    private stateActions: typeof actionCreators;

    constructor(api: UsersRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadUsers = async () => {
        this.stateActions.SendUserListRequest()
        let response = await this.api.ReadUsers()
        if (response instanceof Error) {
            this.stateActions.UserListError(response.message)
            return
        } else {
            this.stateActions.UserListSuccess(response)
        }
    }

    public DeleteUser = async (id: UniqueId) => {
        this.stateActions.UserDeleteRequest()
        let response = await this.api.DeleteUser(id)
        if (response instanceof Error) {
            this.stateActions.UserDeleteError(response.message)
            return
        } else {
            this.stateActions.UserDeleteSuccess(id)
        }
    }

    public ReadUserById = async (id: UniqueId) => {
        let response = await this.api.ReadUserById(id)
        return response
    }

    public ReadUserNameById = async (id: UniqueId) => {
        let response = await this.api.ReadUserNameById(id)
        return response
    }

    public ReadDictionaries = async () => {
        let response = await this.api.ReadDictionaries()
        return response
    }

    public CreateUser = async (name: string, login: string, pass: string, role: string, mentodIsId: number, mentodIs2Id: number, headOperatorId: number, isActive: boolean, responseTrans: number, callback?: ()=>void) => {
        this.stateActions.UserCreateRequest()
        let response = await this.api.CreateUser(name, login, pass, role, mentodIsId, mentodIs2Id, headOperatorId, isActive, responseTrans)
        if (response instanceof Error) {
            this.stateActions.UserCreateError(response.message)
            return
        } else {
            this.stateActions.UserCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public UpdateUser = async (id: UniqueId, name: string, login: string, role: string, mentodIsId: number, mentodIs2Id: number, headOperatorId: number, isActive: boolean, responseTrans: number, callback?: ()=>void) => {
        this.stateActions.UserUpdateRequest()
        let response = await this.api.UpdateUser(id, name, login, role, mentodIsId, mentodIs2Id, headOperatorId, isActive, responseTrans)
        if (response instanceof Error) {
            this.stateActions.UserUpdateError(response.message)
            return
        } else {
            this.stateActions.UserUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public ChangePassword = async (id: UniqueId, pass: string, callback?: ()=>void) => {
        this.stateActions.UserUpdateRequest()
        let response = await this.api.ChangePassword(id, pass)
        if (response instanceof Error) {
            this.stateActions.UserUpdateError(response.message)
            return
        } else {
            this.stateActions.UserUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

}